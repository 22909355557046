.alert-page {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    min-width: 490px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding: 20px; /* Ajout du padding */
  }

  #alert-title {
    color: #4f23e2;
    margin-bottom: 20px; /* Ajout de la marge en bas */
  }

  .alert-page-box {
    margin-bottom: 10px;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .checkbox-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px; /* Ajustez selon vos préférences */
  }

  /* Enlever le padding autour de la checkbox et définir sa taille */
  .checkbox-item input[type="checkbox"] {
    margin: 0; /* Enlever le margin par défaut de la checkbox */
    width: 16px; /* Ajustez selon la taille souhaitée */
    height: 16px; /* Ajustez selon la taille souhaitée */
  }

  .alert-btn {
    background-color: #4f23e2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .alert-btn:hover {
    background-color: #3c1eaa;
  }

  /* Styles supplémentaires pour l'espacement entre les éléments */
  .alert-page > * {
    margin-bottom: 20px;
  }

  /* Retirer la marge du dernier élément pour éviter un espace supplémentaire en bas */
  .alert-page > *:last-child {
    margin-bottom: 0;
  }

#roles-container {
  display: none;
  flex-direction: column;
}

#classes-container {
  display: flex;
  flex-direction: column;
}

.popup {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
}

@media screen and (max-width: 925px) {
  .breakline {
    border: none;
    border-bottom: 2px solid #4f23e2;
    height: 5px;
    width: calc(100% - 170px);
    margin-left: 10px;
  }
}


@media screen and (min-width: 925px) {
  .breakline {
    border: none;
    border-bottom: 2px solid #4f23e2;
    height: 5px;
    width: 100%;
    margin-left: 10px;
  }
}

.alert-page {
  height: calc(100vh - 150px);
  overflow-y: auto;
  width: calc(100% - 80px);
}

.alert-page-container {
  position: relative;

  .day-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
  }

  .alert-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 26px;
    height: 112px;
    width: fit-content;
    border: 2px solid #4F23E2;

    .content {
        width: 100%;
        height: 100%;
        margin: 5px 5px 5px 10px;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: fit-content;

            .see-more-inverted {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                color: #4F23E2;
                font-size: 18px;
                font-weight: 600;
                font-family: Inter;
                cursor: pointer;
                border: none;
                background-color: white;
                padding: 0px;

                .img {
                    margin-left: 4px;
                }
            }
        }

        .body {
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            color: black;
            margin-top: 3px;
            width: fit-content;
        }
    }

}
}

.day-title {
  position: relative;
}

#alert-message-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 26px;
  height: auto;
  border: 2px solid #4F23E2;
  padding: 10px;
  margin-bottom: 25px;
  margin-right: 25px;
  font-family: Inter;

  #alert-message-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;

    #alert-message-header-content {
      display: flex;
      flex-direction: row;
      gap: 5px;

      #content-information {
        display: flex;
        flex-direction: column;
        font-size: 28px;
        width: 100%;
      }
    }
  }

  #alert-message-message {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 26px;
    height: 100%;
    border: 2px solid #4F23E2;
    padding: 10px;
    overflow-y: auto;
    font-size: 20px;
  }
}

#alert-btn {
  background-color: #4f23e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: Inter;
  width: 363px;
  height: 52px;
  font-size: 22px;
}

#interaction-btn-container {
  display: flex;
  flex-direction: row;
  gap: 50px;

  button {
    background-color: #4f23e2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-family: Inter;
    width: auto;

  }

  .no-interaction-btn {
    background-color: #3c1eaa;
  }
}

@media screen and (max-height: 600px) {
  #alert-message-container {
    height: 400px;
  }
}

@media screen and (min-height: 601px) {
  #alert-message-container {
    min-height: 400px;

    #alert-message-message {
      min-height: 400px;
    }
  }
}

.page-alert {
  height: calc(100vh - 115px);
  overflow-y: auto;
}
