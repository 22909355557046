/* Styles de base */
/* Sélection des boutons */
button {
    background-color: white;
    color: #4f23e2;
    border: 2px solid #4f23e2;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .button-container {
    display: flex;
    margin-top: 5px;
  }

  .button-section {
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    background-color: #ffffff;
    border-top: 2px solid #4f23e2;
    border-bottom: 2px solid #4f23e2;
    color: #4f23e2;
  }

  .button-left {
    border-left: 2px solid #4f23e2;
  }

  .button-right {
    border-right: 2px solid #4f23e2;
  }

  .button-section:hover {
    background-color: #4f23e2;
    color: #ffffff;
  }

  .button-section.active {
    background-color: #4f23e2;
    color: #ffffff;
  }

  .button-section:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .button-section:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  button.active {
    background-color: #4f23e2;
    color: white;
  }

  /* Date du jour */
  #dateFilter {
    background-color: white;
    color: black;
    border: 1px solid #4f23e2; /* Bordure tout le temps */
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 16px;
    margin-left: 5px;
  }

  /* Bordure du dateFilter quand il est focus */
  #dateFilter:focus {
    border-color: #4f23e2; /* Changer la couleur de la bordure lorsqu'il est sélectionné */
  }

  /* Flèches pour changer de semaine/jour */
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  /* Graphique */

  .chart-container {
    position: relative;
    width: 100%;
    height: 400px; /* Hauteur initiale */
    max-width: 800px; /* Largeur maximale */
    margin: auto; /* Centrage */
  }

  canvas {
    color: white;
    background-color: #4f23e2;
    border-radius: 10px;
    width: 400px;
    height: 400px;
  }

  /* Media Queries pour la réactivité */
  @media screen and (max-width: 768px) {
    /* Styles pour les écrans jusqu'à 768px de largeur */
    button {
      font-size: 14px;
    }

    #dateFilter {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 576px) {
    /* Styles pour les écrans jusqu'à 576px de largeur */
    button {
      font-size: 12px;
      padding: 8px 16px;
    }

    #dateFilter {
      font-size: 12px;
      padding: 3px 6px;
    }
  }

  .page-content {
    display: flex;
    flex-direction: column;
  }

  .graph-container {
    display: flex;
    flex-wrap: wrap;
  }

  #moodChart {
    flex: 1;
    margin-right: 20px; /* Marge entre le graphique et le cadre */
  }

  .average-rectangle {
    background-color: #f0f0f0;
    border: 1px solid #4f23e2;
    padding: 10px;
    min-width: 200px;
    border-radius: 5px;
  }

  .progress-bar {
    background-color: white; /* Couleur du vide */
    border: 1px solid #4f23e2; /* Bordure */
    border-radius: 20px; /* Arrondi */
    width: 100%; /* Largeur maximale */
    height: 20px; /* Hauteur */
    margin-top: 20px; /* Marge supérieure */
  }

  .progress {
    background-color: white; /* Couleur du contenu */
    border: 1px solid #4f23e2;
    height: 100%; /* Remplissage à 100% */
    border-radius: 18px; /* Arrondi pour s'aligner avec la bordure */
  }

  .progress::-webkit-progress-value {
    background-color: #4f23e2;
  }

  /* Style pour la liste déroulante */
#classFilter {
  background-color: white;
  color: #4f23e2;
  border: 2px solid #4f23e2;
  border-radius: 20px;
  padding: 10px 30px 10px 10px; /* Ajout de padding pour la flèche */
  font-size: 16px;
  appearance: none;
  position: relative;
}

#classFilter:focus {
  border-color: #4f23e2;
  outline: none;
}

/* Ajout d'une flèche à droite */
#classFilter::after {
  content: '▼';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #4f23e2;
  font-size: 12px;
}

/* Style pour les options de la liste déroulante */
#classFilter option {
  background-color: white;
  color: black;
  padding: 10px;
}

/* Ajustements pour la réactivité */
@media screen and (max-width: 768px) {
  #classFilter {
      font-size: 14px;
      padding: 8px 26px 8px 8px; /* Ajustement du padding */
  }
}

@media screen and (max-width: 576px) {
  #classFilter {
      font-size: 12px;
      padding: 6px 24px 6px 6px; /* Ajustement du padding */
  }
}

.stat-page-content {
  height: calc(100% - 200px);
}