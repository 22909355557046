#popup-box {
    background-color: white;
    position: absolute;
    left: 20%;
    top: 15%;
    width: 45rem;
}

#p-box {
    background-color: white;
}


.pop-content {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.pop-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pop-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pop-input {
    font-size: large;
    margin-top: 1.5rem;
    width: 25rem;
    height: 4rem;
    background-color: rgb(230,230,230);
    border: 0;
    padding-left: 1rem;
}

.pop-info {
    text-align: center;
    font-size: large;
}

.pop-input-file {
    font-size: large;
    margin-top: 1.5rem;
    width: 25rem;
    height: 4rem;
    background-color: rgb(230,230,230);
    border: 0;
    padding-left: 1rem;
    padding-top: 2rem;
  }

.pop-header {
    background-color: rgb(79, 35, 226);
    color: white;
    text-align: center;
    display: grid;
}

.btn-close {
    justify-self: end;
}

/**************************************************************/
.popup-modal-container {
    background-color: white;
    z-index: 10;
    border-radius: 15px;
    padding: 25px;
    font-family: Inter;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .close-btn {
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 4px;
        right: -8px;
        border-radius: 50%;

        img {
            width: 25px;
            border-radius: 15px;
        }
    }

    label {
        display: flex;
        flex-direction: column;
        gap: 10px;

        input, select {
            width: fit-content;
            border: 2px solid #4f23e2;
            border-radius: 15px;
            height: 25px;
            padding-left: 10px;
            background-color: white;
            font-family: 'Inter';
            font-size: 15px;

            option {
                font-family: 'Inter';
                font-size: 15px;
            }
        }

        textarea {
            height: 15%;
            width: 100%;
            border: 2px solid #4f23e2;
            border-radius: 15px;
            padding-left: 10px;
            background-color: white;
        }

        #select-classes .css-13cymwt-control, #select-classes .react-select__menu {
            border: 2px solid #4f23e2;
            border-radius: 15px;
        }

        .label-content-warning {
            text-align: center;
            width: 450px;
        }
    }

    .popup-btn {
        background-color: #4f23e2;
        cursor: pointer;
        width: fit-content;
        border: 1px solid #4f23e2;
        border-radius: 15px;
        color: white;
        font-family: Inter;
        padding: 5px 10px;
    }

    .popup-btn:disabled {
        background-color: #b3b3b3;
        cursor: not-allowed;
        width: fit-content;
        border: 1px solid #4b4b4b;
        border-radius: 15px;
        color: white;
        font-family: Inter;
        padding: 5px 10px;
    }

    .popup-btn:hover:enabled {
        background-color: #3b1ca9; /* Darker color on hover */
    }

    .content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100vh - 104px);
        gap: 25px;

        .notification-container {
            background-color: #D9D9D9;
            border-radius: 26px;
            padding: 10px;
            gap: 25px;
            display: flex;
            flex-direction: column;

            .notification-header {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                font-family: Inter;
                font-size: 20px;
                gap: 10px;
            }
            .notification-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                font-family: Inter;
                font-size: 16px;
            }
        }
    }
}

.picture-input {
    width: 285px !important;
}

.notification {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    z-index: 999;
  }

  .success {
    background-color: #4CAF50; /* Vert */
  }

  .error {
    background-color: #f44336; /* Rouge */
  }


  label {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input, select, textarea {
        width: 90%;
        border: 2px solid #4f23e2;
        border-radius: 15px;
        height: 25px;
        padding-left: 10px;
        background-color: white;
    }

    textarea {
        height: 15%;
    }

    #select-classes .css-13cymwt-control, #select-classes .react-select__menu {
        border: 2px solid #4f23e2;
        border-radius: 15px;
    }

    .label-content-warning {
        text-align: center;
        width: 450px;
    }
}

.form-profile-modif {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-content: center;
}

.desanonym-popup-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-content: center;
}
.classes-gestion-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-content: center;
}

.class-input-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px  0 20px ;
}

.class-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-content: center;
    overflow-y: scroll;
    height: 55vh;
    padding-left: 10px;
    padding-right: 10px;
}

.class-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-content: center;
    justify-content: space-between;
    border: 1px solid lightgrey;
    box-shadow: 5px 5px 5px 0px lightgray;
    border-radius: 5px;
    padding: 10px;
}

.icon-popup {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.icon-popup:hover {
    transition: 0.2s;
    height: 23px;
}