.page-content {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    height: calc(100vh - 129px);
}

.establishment-banner {
    border-radius: 5px;
    background-color: #c7abff;
    gap: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.banner-text {
    color: white;
    font-size: larger;
    font-weight: bold;
}

.banner-btn {
    display: flex;
    align-content: center;
    margin-bottom: -1.5rem;
}

.account-div {
    display: flex;
    flex-direction: column;
    right: 0;
    padding-top: 2rem;
    margin-right: 2rem;
}