.questionnaire-btn {
    border-radius: 5px;
    margin-top: 10px;
    width: 250px;
    height: 8vh;
}

.moodBtn {
    background-color: blueviolet;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    height: 35px;
    min-width: 110px;
}