.error-message-no-page {
    color: white;
}

#no-page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}