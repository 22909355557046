.profile {
    display: flex;
    flex-direction: row;
    align-items: center;

    .img {
        height: 35px;
        width: 35px;
        border-radius: 100%;
    }

    .profile-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: start;
        margin-left: 10px;
        gap: 10px;

        .lastname {
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            color: #4F23E2;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 5px;
        }

        .lastname-white {
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            color: white;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .title {
            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            color: #9699FF;
        }

        .title-white {
            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            color: #9699FF;
        }
    }
}