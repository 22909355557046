#category-container {
    display: flex;
    flex-direction: row;
    gap: 25px;
    overflow: auto;
    white-space: nowrap;
    height: 10vh;
    align-items: center;
}

.category-btn {
  max-width: 355px;
  height: 50px;
  box-shadow: 5px 5px 5px 0px lightgray;
  border: 1px solid lightgrey;
  border-radius: 15px;
  font-size: 22px;
  background-color: white;
  color: #4F23E2;
  cursor: pointer;
}

.selected-btn {
  border: 1px solid #4F23E2;
  border-radius: 15px;
  background-color: #4F23E2;
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.category-btn:hover {
  border: 1px solid #4F23E2;
  border-radius: 15px;
  background-color: #4F23E2;
  color: white;
  font-size: 22px;
  cursor: pointer;
}

#help-container {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  justify-content: space-between;
}

#filtered-contacts-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  overflow: auto;
  box-shadow: 5px 5px 5px 0px lightgray;
  border: 1px solid lightgrey;
  max-height: calc(100vh - 225px);
  width: 40%;
  border-radius: 15px;
}

.contact-btn {
  width: 95%;
  min-height: 50px;
  align-items: center;
  border: 1px solid transparent;
  padding-left: 20px;
  display: flex;
  background-color: white;
  color: #4F23E2;
  border-radius: 15px;
  font-size: 22px;
  cursor: pointer;
  padding: 10px;
}

.selected-contact-btn {
  width: 95%;
  min-height: 50px;
  border: 1px solid #4F23E2;
  background-color: #4F23E2;
  color: white;
  font-size: 22px;
  border-radius: 15px;
  font-family: Inter;
  padding: 10px;
  cursor: pointer;
}

.contact-btn:hover {
  width: 95%;
  min-height: 50px;
  border: 1px solid #4F23E2;
  background-color: #4F23E2;
  color: white;
  font-size: 22px;
  border-radius: 15px;
  font-family: Inter;
  padding: 10px;
  cursor: pointer;
}

.contact-container {
  display: flex;
  flex-direction: row;
  gap: 25px;
  overflow: auto;
}

.contact-content-container {
  box-shadow: 5px 5px 5px 0px lightgray;
  border: 1px solid lightgrey;
  border-radius: 15px;
  background-color: white;
  width: 56%;
  height: 100%;
  padding: 15px;
  margin-left: 25px;
  font-family: Inter;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

#contact-profile {
  gap: 10px;
}

#contact-title {
  font-size: 30px;
}

.contact-element-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 18px;
}

.contact-element-title {
  margin: 0;
}

.contact-element-content {
  color: #4F23E2;
  margin: 0;
}

.notification {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  z-index: 999;
}

.success {
  background-color: #4CAF50; /* Vert */
}

.error {
  background-color: #f44336; /* Rouge */
}

/* Style général du bouton */
button {
  background-color: white; /* Couleur de fond initiale */
  color: #4F23E2;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

/* Changement de couleur lors du survol */
button:hover {
  background-color: #3a1ab3; /* Couleur de fond lorsque le bouton est survolé */
  color: white;
}

/* Changement de couleur lors du clic */
button:active {
  background-color: #2c1380; /* Couleur de fond lorsque le bouton est cliqué */
  color: white;
  transform: scale(0.98); /* Légère réduction de taille pour l'effet de clic */
}

