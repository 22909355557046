.chat-page {
  height: 100%;
  width: 100%;

  .content {
    margin-left: 29px;
    width: calc(100% - (29px * 2));
    height: calc(100% - 104px);

    .messaging-page {
      height: calc(100% - 29px);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .chat-sidebar {
        box-sizing: border-box;
        border: 1px solid lightgrey;
        box-shadow: 5px 5px 5px 0px lightgray;
        border-radius: 5px;
        height: 100%;
        width: 250px;

        .top {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 10px;

          .new-conversation-button {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            width: 230px;
            cursor: pointer;

            background-color: #4F23E2;
            border-radius: 5px;

            color: white;
            font-size: 14px;
            font-family: 'Inter';
            font-weight: 500;
          }

          .new-conversation-button:hover {
            background-color: #3b1ca9;
          }

          .divider {
            margin-top: 4px;
            height: 4px;
            width: 100%;
            background-color: #4F23E2;
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          height: 100%;
          width: 100%;
          margin-left: 0px;

          .conversation-notification {
            width: 15px;
            height: 15px;
            border-radius: 100%;
            background-color: red;
            margin-right: 5px;
          }

          .active-conversation {
            height: 71px;
            width: 100%;
            background-color: #4F23E2;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .text {
              color: white;
              font-size: 16px;
              font-family: Inter;
              font-weight: 600;
              margin-left: 10px;
            }
          }

          .active-conversation:hover {
            background-color: #3b1ca9;
            color: white;
          }

          .conversation {
            height: 71px;
            width: 100%;
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .text {
              margin-left: 10px;
              color: #4F23E2;
              font-size: 16px;
              font-family: Inter;
              font-weight: 600;
            }
          }

          .conversation:hover {
            text-decoration: underline;
          }
        }
      }

      .chat {
        width: 100%;
        height: 100%;
        border: 1px solid lightgrey;
        box-shadow: 5px 5px 5px 0px lightgray;
        border-radius: 26px;
        margin-left: 50px;
        box-sizing: border-box;

        .chat-content {
          width: 100%;
          height: 100%;

          .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            background-color: white;
            border-top-left-radius: 35px;
            border-top-right-radius: 30px;
            border-bottom: 1px solid #9699FF;

            .top-info {
              display: flex;
              flex-direction: column;
              margin-left: 20px;
              gap: 10px;

              .conv-name {
                font-size: 32px;
                font-family: Inter;
                font-weight: 600;
                color: black;
              }

              .participants-container {
                display: flex;
                flex-direction: row;
                color: #4F23E2;
                font-size: 18px;
                font-family: Inter;
              }
            }


            .report-btn {
              border-radius: 5px;
              background-color: #4F23E2;
              border: none;
              color: white;
              font-size: 22px;
              font-family: Inter;
              font-weight: 400;
              height: 34px;
              margin-right: 12px;
              cursor: pointer;
              align-items: center;
              display: flex;
            }

            .report-btn:hover {
              background-color: #3b1ca9;
            }
          }

          .bottom {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;

            .left {
              display: flex;
              flex: 0.70;
              flex-direction: column;
              justify-content: start;
              align-items: start;
              max-height: 80%;

              .top2 {
                // flex: 0.89;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                .message-list {
                  width: calc(100% - 20px);
                  height: calc(100% - 20px);
                  margin: 10px 0px 10px 10px;

                  .my-messages {
                    background-color: #FFD2D5;
                    width: 40%;
                    margin-left: calc(60% - 40px);
                    border-radius: 26px;
                    height: fit-content;
                    min-height: 100px;
                    color: #4F23E2;

                    .message-header {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                      border-bottom: 1px #4F23E2 solid;
                      white-space: nowrap;
                      font-size: 12px;
                      font-family: Inter;
                      font-weight: 600;
                      padding: 5px 10px 5px 10px;
                    }

                    .message-content {
                      font-size: 16px;
                      font-weight: 600;
                      font-family: Inter;
                      color: #4F23E2;
                    }

                    .message-content2 {
                      padding: 5px 10px 5px 10px;
                      overflow-wrap: anywhere;
                      width: 95%;
                    }

                    .with-file {
                      display: flex;
                      flex-direction: column;
                      justify-content: start;
                      align-items: start;
                      width: 100%;

                      .file {
                        padding: 5px 10px 5px 10px;
                        width: calc(100% - 20px);
                        border-top: 1px #4F23E2 solid;
                        margin-top: 15px;
                      }
                    }
                  }

                  .other-messages {
                    background-color: #4F23E2;
                    width: 40%;
                    border-radius: 26px;
                    margin-left: 40px;
                    height: fit-content;
                    min-height: 100px;
                    color: white;

                    .message-header {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                      border-bottom: 1px white solid;
                      color: white !important;
                      font-size: 12px;
                      font-family: Inter;
                      font-weight: 600;
                      white-space: nowrap;
                      padding: 5px 10px 5px 10px;
                    }

                    .message-content {
                      font-size: 16px;
                      font-weight: 600;
                      font-family: Inter;
                      color: white;
                    }

                    .message-content2 {
                      padding: 5px 10px 5px 10px;
                    }

                    .with-file {
                      display: flex;
                      flex-direction: column;
                      justify-content: start;
                      align-items: start;
                      width: 100%;

                      .file {
                        padding: 5px 10px 5px 10px;
                        width: calc(100% - 20px);
                        border-top: 1px white solid;
                        margin-top: 15px;
                      }
                    }
                }

                .message-identification {
                  display: flex;
                  gap: 20px;

                  .img {
                    height: 35px;
                    width: 35px;
                    border-radius: 100%;
                  }

                  .image-right {
                    display: flex;
                    margin-top: -40px;
                  }

                  .image-left {
                    display: flex;
                    margin-left: 0px;
                    margin-top: -40px;
                  }

                  .message-time {
                    margin-bottom: 15px;
                    color: gray;
                  }
                }
              }

              }

              .bottom2 {
                // flex: 0.11;
                border-top: none;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: center;

                .column {
                  display: flex;
                  flex-direction: column;
                  justify-content: end;
                  align-items: center;
                  margin-bottom: 10px;
                  width: 100%;
                  height: 100%;

                  .file-name {
                    color: #4F23E2;
                    font-size: 16px;
                    font-family: Inter;
                    font-weight: 600;
                    margin-bottom: 8px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .send-button {
                      color: white;
                      font-size: 16px;
                      font-family: Inter;
                      font-weight: 600;
                      background-color: #4F23E2;
                      border: none;
                      border-radius: 100px;
                      margin-left: 5px;
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }

                  .message-input {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 95%;
                    height: 50%;

                    .file-input {
                      margin-right: 6px;
                      cursor: pointer;
                      input {
                        width: 0;
                        height: 0;
                      }
                    }

                    .message-area {
                      border: none;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;

                      input {
                        width: 100%;
                        height: 25px;
                        border-radius: 5px;
                        background-color: #EAEAEA;
                        z-index: 99;
                        color: #4F23E2;
                        font-size: 16px;
                        font-family: Inter;
                        font-weight: 600;
                        border: none;
                        padding-left: 10px;
                      }

                      textarea:focus, input:focus {
                        outline: none;
                      }

                      .send-button {
                        border-radius: 5px;
                        background-color: #4F23E2;
                        height: 25px;
                        width: 100px;
                        z-index: 0;
                        color: white;
                        font-size: 16px;
                        font-family: Inter;
                        font-weight: 600;
                        border: none;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }

                      .send-button:hover {
                        background-color: #3b1ca9;
                      }
                    }
                  }
                }

              }
            }

            .right {
              flex: 0.20;
              width: 100%;
              height: 100%;
              border-left: 4px #4F23E2 solid;
              overflow-y: auto;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              .user-profile {
                border: 3px #4F23E2 solid;
                border-radius: 26px;
                margin-top: 6px;
                margin-right: 2px;
                margin-left: 2px;
                width: fit-content;
                height: fit-content;
              }
            }
          }
        }
      }
    }
  }
}

.notification {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  z-index: 999;
}

.success {
  background-color: #4CAF50; /* Vert */
}

.error {
  background-color: #f44336; /* Rouge */
}

.add-participants-btn {
  background-color: #4CAF50; /* Couleur verte */
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
  height: 34px;
  margin-right: 12px;
  cursor: pointer;
  align-items: center;
  display: flex;
  font-family: 'Inter';

  &:hover {
    background-color: #45a049;
  }
}

.leave-conversation-btn {
  background-color: #4CAF50; /* Couleur verte */
  color: white;
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 15px;
  height: 34px;

  &:hover {
    background-color: #45a049;
  }
}

.leave-conversation-btn {
  background-color: #f44336; /* Couleur rouge */

  &:hover {
    background-color: #d32f2f;
  }
}

.popup-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leave-conversation-popup {
  text-align: center;
  padding: 20px;

  h2 {
    margin-bottom: 20px;
  }

  .confirm-leave-btn, .cancel-leave-btn {
    padding: 10px 15px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  .confirm-leave-btn {
    background-color: #f44336;
    color: white;

    &:hover {
      background-color: #d32f2f;
    }
  }

  .cancel-leave-btn {
    background-color: #e0e0e0;
    color: black;

    &:hover {
      background-color: #bdbdbd;
    }
  }
}


////////////////////////////////////////////////////////////
.conversation-popup {
  width: 100vw !important;
}

.messaging-popup {
  height: calc(100% - 29px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .chat-sidebar {
    box-sizing: border-box;
    border: 1px solid lightgrey;
    box-shadow: 5px 5px 5px 0px lightgray;
    border-radius: 5px;
    height: 100%;
    width: 250px;

    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;

      .new-conversation-button {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 230px;
        cursor: pointer;

        background-color: #4F23E2;
        border-radius: 5px;

        color: white;
        font-size: 14px;
        font-family: 'Inter';
        font-weight: 500;
      }

      .new-conversation-button:hover {
        background-color: #3b1ca9;
      }

      .divider {
        margin-top: 4px;
        height: 4px;
        width: 100%;
        background-color: #4F23E2;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      height: 100%;
      width: 100%;
      margin-left: 0px;

      .conversation-notification {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background-color: red;
        margin-right: 5px;
      }

      .active-conversation {
        height: 71px;
        width: 100%;
        background-color: #4F23E2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .text {
          color: white;
          font-size: 16px;
          font-family: Inter;
          font-weight: 600;
          margin-left: 10px;
        }
      }

      .active-conversation:hover {
        background-color: #3b1ca9;
        color: white;
      }

      .conversation {
        height: 71px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .text {
          margin-left: 10px;
          color: #4F23E2;
          font-size: 16px;
          font-family: Inter;
          font-weight: 600;
        }
      }

      .conversation:hover {
        text-decoration: underline;
      }
    }
  }

  .chat {
    width: 100%;
    height: 100%;
    border: 1px solid lightgrey;
    box-shadow: 5px 5px 5px 0px lightgray;
    border-radius: 26px;
    margin-left: 50px;
    box-sizing: border-box;

    .chat-content {
      width: 100%;
      height: 100%;

      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        background-color: white;
        border-top-left-radius: 35px;
        border-top-right-radius: 30px;
        border-bottom: 1px solid #9699FF;

        .top-info {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          gap: 10px;

          .conv-name {
            font-size: 32px;
            font-family: Inter;
            font-weight: 600;
            color: black;
          }

          .participants-container {
            display: flex;
            flex-direction: row;
            color: #4F23E2;
            font-size: 18px;
            font-family: Inter;
          }
        }


        .report-btn {
          border-radius: 5px;
          background-color: #4F23E2;
          border: none;
          color: white;
          font-size: 22px;
          font-family: Inter;
          font-weight: 400;
          height: 34px;
          margin-right: 12px;
          cursor: pointer;
          align-items: center;
          display: flex;
        }

        .report-btn:hover {
          background-color: #3b1ca9;
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .left {
          display: flex;
          flex: 0.70;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          max-height: 80%;

          .top2 {
            // flex: 0.89;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 55vh;

            .message-list {
              width: calc(100% - 20px);
              height: calc(100% - 20px);
              margin: 10px 0px 10px 10px;

              .my-messages {
                background-color: #FFD2D5;
                width: 40%;
                margin-left: calc(60% - 40px);
                border-radius: 26px;
                height: fit-content;
                min-height: 100px;
                color: #4F23E2;

                .message-header {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px #4F23E2 solid;
                  white-space: nowrap;
                  font-size: 12px;
                  font-family: Inter;
                  font-weight: 600;
                  padding: 5px 10px 5px 10px;
                }

                .message-content {
                  font-size: 16px;
                  font-weight: 600;
                  font-family: Inter;
                  color: #4F23E2;
                }

                .message-content2 {
                  padding: 5px 10px 5px 10px;
                  overflow-wrap: anywhere;
                  width: 95%;
                }

                .with-file {
                  display: flex;
                  flex-direction: column;
                  justify-content: start;
                  align-items: start;
                  width: 100%;

                  .file {
                    padding: 5px 10px 5px 10px;
                    width: calc(100% - 20px);
                    border-top: 1px #4F23E2 solid;
                    margin-top: 15px;
                  }
                }
              }

              .other-messages {
                background-color: #4F23E2;
                width: 40%;
                border-radius: 26px;
                margin-left: 40px;
                height: fit-content;
                min-height: 100px;
                color: white;

                .message-header {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px white solid;
                  color: white !important;
                  font-size: 12px;
                  font-family: Inter;
                  font-weight: 600;
                  white-space: nowrap;
                  padding: 5px 10px 5px 10px;
                }

                .message-content {
                  font-size: 16px;
                  font-weight: 600;
                  font-family: Inter;
                  color: white;
                }

                .message-content2 {
                  padding: 5px 10px 5px 10px;
                }

                .with-file {
                  display: flex;
                  flex-direction: column;
                  justify-content: start;
                  align-items: start;
                  width: 100%;

                  .file {
                    padding: 5px 10px 5px 10px;
                    width: calc(100% - 20px);
                    border-top: 1px white solid;
                    margin-top: 15px;
                  }
                }
            }

            .message-identification {
              display: flex;
              gap: 20px;

              .img {
                height: 35px;
                width: 35px;
                border-radius: 100%;
              }

              .image-right {
                display: flex;
                margin-top: -40px;
              }

              .image-left {
                display: flex;
                margin-left: 0px;
                margin-top: -40px;
              }

              .message-time {
                margin-bottom: 15px;
                color: gray;
              }
            }
          }

          }

          .bottom2 {
            // flex: 0.11;
            border-top: none;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;

            .column {
              display: flex;
              flex-direction: column;
              justify-content: end;
              align-items: center;
              margin-bottom: 10px;
              width: 100%;
              height: 100%;

              .file-name {
                color: #4F23E2;
                font-size: 16px;
                font-family: Inter;
                font-weight: 600;
                margin-bottom: 8px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .send-button {
                  color: white;
                  font-size: 16px;
                  font-family: Inter;
                  font-weight: 600;
                  background-color: #4F23E2;
                  border: none;
                  border-radius: 100px;
                  margin-left: 5px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              .message-input {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 95%;
                height: 50%;

                .file-input {
                  margin-right: 6px;
                  cursor: pointer;
                  input {
                    width: 0;
                    height: 0;
                  }
                }

                .message-area {
                  border: none;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;

                  input {
                    width: 100%;
                    height: 25px;
                    border-radius: 5px;
                    background-color: #EAEAEA;
                    z-index: 99;
                    color: #4F23E2;
                    font-size: 16px;
                    font-family: Inter;
                    font-weight: 600;
                    border: none;
                    padding-left: 10px;
                  }

                  textarea:focus, input:focus {
                    outline: none;
                  }

                  .send-button {
                    border-radius: 5px;
                    background-color: #4F23E2;
                    height: 25px;
                    width: 100px;
                    z-index: 0;
                    color: white;
                    font-size: 16px;
                    font-family: Inter;
                    font-weight: 600;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  .send-button:hover {
                    background-color: #3b1ca9;
                  }
                }
              }
            }

          }
        }

        .right {
          flex: 0.20;
          width: 100%;
          height: 100%;
          border-left: 4px #4F23E2 solid;
          overflow-y: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .user-profile {
            border: 3px #4F23E2 solid;
            border-radius: 26px;
            margin-top: 6px;
            margin-right: 2px;
            margin-left: 2px;
            width: fit-content;
            height: fit-content;
          }
        }
      }
    }
  }
}