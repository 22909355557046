.messaging-page {
  height: 100%;
  width: 100%;

  .content {
    margin-left: 29px;
    width: calc(100% - (29px * 2));
    height: calc(100% - 104px);

    .messaging-page {
      height: calc(100% - 29px);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .chat-sidebar {
        box-sizing: border-box;
        border: 1px solid lightgrey;
        box-shadow: 5px 5px 5px 0px lightgray;
        border-radius: 5px;
        height: 100%;
        width: 250px;

        .report-sidebar-header {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;

          .report-sidebar-button {
            font-size: large;
            padding: 5px;
            cursor: pointer;
          }

          .blue-filler {
            background-color: #4F23E2;
            color: white
          }

          .report-sidebar-button:hover {
            font-size: larger;
            transition: 0.1s;
          }
        }

        .top {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 5px;

          .new-conversation-button {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            width: 230px;
            cursor: pointer;

            background-color: white;
            border: 4px #4F23E2 solid;
            border-radius: 26px;

            color: black;
            font-size: 16px;
            font-family: Inter;
            font-weight: 600;
          }

          .new-conversation-button:hover {
            text-decoration: underline;
          }

          .divider {
            margin-top: 4px;
            height: 4px;
            width: 100%;
            background-color: #4F23E2;
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          height: 100%;
          width: 100%;
          margin-left: 0px;
          overflow-y: auto;

          .conversation-notification {
            width: 15px;
            height: 15px;
            border-radius: 100%;
            background-color: red;
            margin-right: 5px;
          }

          .active-conversation {
            height: 71px;
            width: 100%;
            background-color: #4F23E2;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .text {
              color: white;
              font-size: 16px;
              font-family: Inter;
              font-weight: 600;
              margin-left: 10px;
            }
          }

          .active-conversation:hover {
            text-decoration: underline;
            color: white;
          }

          .conversation {
            height: 71px;
            width: 100%;
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .text {
              margin-left: 10px;
              color: #4F23E2;
              font-size: 16px;
              font-family: Inter;
              font-weight: 600;
            }
          }

          .conversation:hover {
            text-decoration: underline;
          }
        }
      }

      .chat {
        width: 100%;
        height: 100%;
        border: 1px solid lightgrey;
        box-shadow: 5px 5px 5px 0px lightgray;
        border-radius: 26px;
        margin-left: 50px;
        box-sizing: border-box;

        .chat-content {
          width: 100%;
          height: 100%;

          .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            background-color: white;
            border-top-left-radius: 35px;
            border-top-right-radius: 30px;
            border-bottom: 1px solid #9699FF;

            .conv-name {
              font-size: 32px;
              font-family: Inter;
              font-weight: 600;
              color: black;
              margin-left: 20px;

              .report-chat-icon {
                width: 20px;
                cursor: pointer;
              }
            }

            .report-btn {
              border-radius: 26px;
              background-color: white;
              border: none;
              color: #4F23E2;
              font-size: 24px;
              font-family: Inter;
              font-weight: 600;
              width: 132px;
              height: 34px;
              margin-right: 12px;
              cursor: pointer;
            }

            .report-btn:hover {
              text-decoration: underline;
            }
          }

          .bottom {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: start;
            height: calc(100% - 64px);
            width: 100%;

            .left {
              flex: 0.85;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: start;

              .top2 {
                flex: 0.89;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                .report-message {
                  font-size: 20px;
                  font-weight: 600;
                  font-family: Inter, serif;
                  padding: 5px;
                  white-space: break-spaces;
                }

                .message-list {
                  width: calc(100% - 20px);
                  height: 100%;
                  margin: 10px 0px 10px 10px;

                  .my-messages {
                    background-color: #FFD2D5;
                    width: 40%;
                    margin-left: 60%;
                    border-radius: 26px;
                    height: fit-content;
                    min-height: 100px;
                    margin-bottom: 20px;
                    color: #4F23E2;

                    .message-header {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                      border-bottom: 1px #4F23E2 solid;
                      white-space: nowrap;
                      font-size: 12px;
                      font-family: Inter;
                      font-weight: 600;
                      padding: 5px 10px 5px 10px;
                    }

                    .message-content {
                      font-size: 16px;
                      font-weight: 600;
                      font-family: Inter;
                      color: #4F23E2;
                    }

                    .message-content2 {
                      padding: 5px 10px 5px 10px;
                    }

                    .with-file {
                      display: flex;
                      flex-direction: column;
                      justify-content: start;
                      align-items: start;
                      width: 100%;

                      .file {
                        padding: 5px 10px 5px 10px;
                        width: calc(100% - 20px);
                        border-top: 1px #4F23E2 solid;
                        margin-top: 15px;
                      }
                    }
                  }

                  .other-messages {
                    background-color: #4F23E2;
                    width: 40%;
                    border-radius: 26px;
                    height: fit-content;
                    min-height: 100px;
                    margin-bottom: 20px;
                    color: white;

                    .message-header {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                      border-bottom: 1px white solid;
                      color: white !important;
                      font-size: 12px;
                      font-family: Inter;
                      font-weight: 600;
                      white-space: nowrap;
                      padding: 5px 10px 5px 10px;
                    }

                    .message-content {
                      font-size: 16px;
                      font-weight: 600;
                      font-family: Inter;
                      color: white;
                    }

                    .message-content2 {
                      padding: 5px 10px 5px 10px;
                    }

                    .with-file {
                      display: flex;
                      flex-direction: column;
                      justify-content: start;
                      align-items: start;
                      width: 100%;

                      .file {
                        padding: 5px 10px 5px 10px;
                        width: calc(100% - 20px);
                        border-top: 1px white solid;
                        margin-top: 15px;
                      }
                    }
                  }

                }
              }

              .bottom2 {
                flex: 0.11;
                border-top: 3px #4F23E2 solid;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: center;

                .column {
                  display: flex;
                  flex-direction: column;
                  justify-content: end;
                  align-items: center;
                  margin-bottom: 10px;
                  width: 100%;
                  height: 100%;

                  .file-name {
                    color: #4F23E2;
                    font-size: 16px;
                    font-family: Inter;
                    font-weight: 600;
                    margin-bottom: 8px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .send-button {
                      color: white;
                      font-size: 16px;
                      font-family: Inter;
                      font-weight: 600;
                      background-color: #4F23E2;
                      border: none;
                      border-radius: 100px;
                      margin-left: 5px;
                      cursor: pointer;
                    }
                  }

                  .message-input {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 95%;
                    height: 50%;

                    .file-input {
                      margin-right: 6px;
                      cursor: pointer;
                      input {
                        width: 0;
                        height: 0;
                      }
                    }

                    .message-area {
                      border: 4px #4F23E2 solid;
                      border-radius: 26px;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;

                      input {
                        width: 100%;
                        border-radius: 26px;
                        background-color: white;
                        z-index: 99;
                        color: #4F23E2;
                        font-size: 16px;
                        font-family: Inter;
                        font-weight: 600;
                        border: none;
                        padding-left: 10px;
                      }

                      textarea:focus, input:focus {
                        outline: none;
                      }

                      .send-button {
                        border-radius: 26px;
                        background-color: #4F23E2;
                        height: 100%;
                        width: 100px;
                        z-index: 0;
                        color: white;
                        font-size: 16px;
                        font-family: Inter;
                        font-weight: 600;
                        border: none;
                        cursor: pointer;
                      }

                      .send-button:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }

              }
            }

            .right {
              flex: 0.25;
              // width: 300px;
              height: 95%;
              border-left: 4px #9699FF solid;
              overflow-y: auto;

              .user-profile {
                border: 3px #4F23E2 solid;
                border-radius: 26px;
                margin-top: 6px;
                margin-right: 2px;
                margin-left: 2px;
                width: calc(100% - 10px);
              }
            }
          }
        }
      }
    }
  }
}
