#header {
    height: 104px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 29px;

    #left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        #title-subtitle {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            #title {
                font-size: 30px;
                font-weight: 600;
                color: black;
                margin-right: 10px;
            }

            #subtitle {
                font-size: 17px;
                font-weight: 600;
                color: black;
                margin-top: 4px;
            }
        }

        #withReturnBtn {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            margin-top: -21px;

            #return-btn {
                margin-right: 6px;
                height: 20px;
                width: 20px;
            }

            #back-text {
                font-size: 18px;
                font-weight: 600;
                color: #4F23E2;
            }
        }

        #withReturnBtn:hover {
            text-decoration: underline;
        }
    }

    .header-btn {
        background-color: #4f23e2;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        font-family: Inter;
        width: auto;
        height: 70px;
        font-size: 18px;
        margin-left: 10px;
    }

    #logo {
        object-fit: scale-down;
        width: 150px;
        height: 104px;
    }
}

/* Media Queries */
@media (max-width: 768px) {
    #header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 0;
        width: 100%;
        height: auto;
    }

    .header-btn {
        width: 100%;
        margin: 10px 0;
        font-size: 16px;
        padding: 8px 16px;
    }

    #left {
        align-items: center;
    }

    #logo {
        width: 100px;
        height: auto;
    }

    #title {
        font-size: 24px;
    }

    #subtitle {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .header-btn {
        font-size: 14px;
        padding: 6px 12px;
    }

    #title {
        font-size: 20px;
    }

    #subtitle {
        font-size: 12px;
    }
}
