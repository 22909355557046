.quest-box {
  width: 100%;
  height: 100%;
  border-radius: 26px;
  box-shadow: 5px 5px 5px 0px lightgray;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  .quest-header {
    width: calc(100% - 28px);
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 18px;
    margin-right: 18px;
    align-items: center;

    .title {
      color: #4F23E2;
      font-size: 30px;
      font-weight: 600;
      font-family: Inter;
    }

    @media (max-height: 600px) {
      .title {
        font-size: 25px;
      }
    }

    .see-more {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      color: #4F23E2;
      font-size: 18px;
      font-weight: 600;
      font-family: Inter;

      .img {
        margin-left: 4px;
      }
    }

  }

  @media (max-height: 600px) {
    .quest-header {
      margin-top: 0;
    }
  }

  .quest-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .no-quest {
      color: #4F23E2;
      font-size: 24px;
      font-weight: 600;
      font-family: Inter;
    }

    .questionnaires {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .questionnaire {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        margin-left: 20px;
        width: 100%;
        height: 100%;
        cursor: pointer;

        .header {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          white-space: pre;
          color: #4F23E2;
          font-size: 24px;
          font-weight: 600;
          font-family: Inter;
          text-overflow: ellipsis;
        }

        @media (max-height: 600px) {
          .header {
            font-size: 18px;
          }
        }

        .body {
          margin-top: 10px;
          width: 100%;
          border: 5px #4F23E2 solid;
          border-radius: 50px;
          height: 100%;

          progress {
            border-radius: 50px;
            border: 0;
            width: 100%;
            height: 100%;
          }

          progress::-webkit-progress-bar {
            border-radius: 50px;
            border: 0;
            width: 100%;
            height: 100%;
          }

          progress::-webkit-progress-value {
            background-color: #4F23E2;
          }

          progress::-moz-progress-bar {
            border-radius: 50px;
            border: 0;
            width: 100%;
            height: 100%;
            background-color: #4F23E2;
          }
        }
      }
    }
  }
}

.notification {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  z-index: 999;
}

.success {
  background-color: #4CAF50; /* Vert */
}

.error {
  background-color: #f44336; /* Rouge */
}
