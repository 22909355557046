.AccountsTable {
    background-color: white;
    display: flex;
    flex-direction: column;
}

.action-td {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
}

.newBreak {
    border: none;
    border-bottom: 2px solid #4f23e2;
    height: 5px;
    width: calc(100%);
    margin-left: 25px;
    margin-right: 35px;
}

.tableTitle {
    margin-left: 30px;
}

.accountTable {
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 15px;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    border-top: 2px solid rgb(79, 35, 226);
    border-bottom: 2px solid rgb(79, 35, 226);
}

.titleTable {
    margin: auto;
    text-align: center;
    display: flex block;
}

.valHead1, .valHead1bis, .valHead2, .valHead3, .valHead3bis, .valHead4, .valHead5, .valHead6 {
    color: white;
}

.tableBlock {
    float: right;
    position: relative;
    display: flex;
    background-color: white;
    justify-content: center;
    margin-left: 30px;
    margin-right: 30px;
}

th {
    background: rgb(79, 35, 226);
}

table, td, th {
    text-align: center;
}

td, th {
    border: 1px solid rgb(79, 35, 226);
    height: 3.5rem;
}

th {
    border-left: 1px solid white;
    border-right: 1px solid white;
    width: 12rem;
}

table {
    margin: auto;
    display: block;
    align-items: center;
    border-collapse: collapse;
}

.account-creation-space {
    background: white;
    float: right;
    display: block;
    justify-content: center;
}

.valHead1 {
    width: 80px;
}

.valHead4, .valHead3bis {
    width: 350px;
}

.valHead5 {
    width: 100px;
}

.suspendBtn {
    width: 40px;
    height: 40px;
}

.valHead1bis, .valHead1 {
    border-left-color: #4f23e2;
}

.valHead3bis, .valHead5 {
    border-right-color: #4f23e2;
}

.notification {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    z-index: 999;
  }

  .success {
    background-color: #4CAF50; /* Vert */
  }

  .error {
    background-color: #f44336; /* Rouge */
  }
