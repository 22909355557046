.profile-img {
  border-radius: 50%;
  width: 130px;
  display: flex;
  justify-content: center;
}

button {
  color: #4F23E2;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.item {
  margin-bottom: 5px;
  color: #4F23E2;
  font-size: 18px;
  font-weight: 600;
  font-family: Inter;
  cursor: pointer;
}

.item:hover {
  text-decoration: underline;
}

/* Changement de couleur lors du survol */
button:hover {
  background-color: #3a1ab3; /* Couleur de fond lorsque le bouton est survolé */
  color: white;
}

/* Changement de couleur lors du clic */
button:active {
  background-color: #2c1380; /* Couleur de fond lorsque le bouton est cliqué */
  transform: scale(0.98); /* Légère réduction de taille pour l'effet de clic */
}

.element-title {
  color: #4F23E2;
  margin: 0;
  justify-content: center;
  display: flex;
}

.element-content {
  margin: 0;
  justify-content: center;
  display: flex;
}

.profile-content {
  font-family: Inter;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.profile-content-container {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.profile-image-container {
  width: 250px;
}

@media screen and (max-height: 600px) {
  .page-height {
    transform-origin: top;
    transform: scale(0.8);
  }
}

@media screen and (min-height: 1200px) {
  .page-height {
      transform-origin: top;
      transform: scale(1.2);
  }
}

/**                                   profile components                           **/
.profile-component-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  max-width: 550px;
  border-radius: 15px;
  box-shadow: 5px 5px 5px 0px lightgray;
  border: 1px solid lightgrey;
  padding-left: 10px;
  padding-bottom: 10px;
  min-width: 550px;


  .profile-component-content-container {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: space-between;

    .profile-information-container {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .profile-content-container {
        .profile-element-title {
          color: #4F23E2;
          margin: 0;
          display: flex;
        }

        .profile-element-content {
          margin: 0;
          display: flex;
        }
      }
    }

    .profile-img-container {
      width: fit-content;
      padding-right: 25px;
      .profile-component-img {
        border-radius: 50%;
        max-width: 200px;
        display: flex;
        justify-content: center;
      }
    }

  }

  .profile-forms-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 600px;
    overflow-y: auto;

    .profile-form-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 25px;
      padding-bottom: 10px;
    }

    .border-bottom {
      border-bottom: 1px solid #4F23E2;
    }
  }

  .report-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-left: -15px;
    max-height: 600px;
    overflow-y: auto;

    .report-container {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      box-shadow: 5px 5px 5px 0px lightgray;
      max-width: 500px;
      border-radius: 15px;
      border: 1px solid lightgrey;
      padding-left: 10px;
      padding-bottom: 10px;
      margin-right: 25px;

      .report-top-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #4F23E2;
        margin-right: 10px;

        .bold-date {
          font-weight: bold;
        }

        .top-profile-container {
          padding-top: 10px;
          padding-right: 15px;
        }
      }

      .report-middle-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #4F23E2;
        margin-right: 10px;
      }

      .report-bottom-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .user-signaled-container {
          margin-bottom: 10px;
          width: 250px;
        }
      }
    }
  }
}

#email {
  border-radius: 15px;
  color: rgb(41, 41, 41, 52%);
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}