#previous-page-btn {
    font-weight: bold;
    font-size: large;
    margin-top: 10px;
    width: 7rem;
    height: 8vh;
    background-color: rgb(79, 35, 226);
    color: white;
    border: 0;
}

.button-css {
    color: white;
    font-size: large;
    font-weight: bold;
    background-color: #8c52ff;
    border: 0;
    cursor: pointer;
}

.emoji-mood-btn {
    height:"100%";
    cursor:"pointer";
    border-radius:"15px";
}