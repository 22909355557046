html, body, .root, .App {
    height: 100vh !important;
    width: 100vw !important;
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: Inter, serif !important;
}

.popup-title {
    font-size: 25px;
    font-weight: 600;
}

.sidebar {
    height: 100%;
    width: fit-content
}

.App {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    background-color: white;
}

.content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.popup-content {
    border-radius: 26px !important;
    padding: 0 !important;
}