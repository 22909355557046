.form-detail-page {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;

    .header {
        display: contents;
    }

    .content {
        margin-left: 29px;
        width: calc(100% - (29px * 2));
        max-width: 1200px;
        height: calc(100% - 200px);
        overflow-y: auto;

        border-radius: 26px;
        border: 1px solid lightgrey;
        box-shadow: 5px 5px 5px 0px lightgray;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        .question {
            width: 100%;
            height: fit-content;
            display:grid;


            .body {
                height: fit-content;
                width: calc(100% - (20px * 2));
                margin-left: 20px;

                .header {
                    width: 100%;
                    height: 83px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    .left {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: start;

                        .nb-question {
                            font-size: 27px;
                            font-family: Inter;
                            font-weight: 600;
                        }

                        .title-question {
                            font-size: 27px;
                            font-family: Inter;
                            font-weight: 600;
                        }
                    }

                    .up-arrow {
                        rotate: 270deg;
                    }

                    .down-arrow {
                        rotate: 90deg;
                        margin-right: 4px;
                    }
                }

                .details {
                    width: 100%;
                    height: fit-content;

                    .text {
                        margin-bottom: 20px;
                        textarea {
                            font-size: 15px;
                            font-family: Inter;
                            font-weight: 500;
                            resize: none;
                            margin-top: 0;
                        }
                    }

                    .emoji {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        margin-bottom: 20px;

                        img {
                            cursor: pointer;
                        }
                    }

                    .multiple {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        margin-bottom: 20px;
                        .answer {
                            color: black;
                            font-size: 16px;
                            font-family: Inter;
                            font-weight: 600;

                            input {
                                width: 20px;
                                height: 20px;
                                background-color: white;
                                border-radius: 6px;
                                vertical-align: middle;
                                border: 2px solid black;
                                appearance: none;
                                -webkit-appearance: none;
                                outline: none;
                                cursor: pointer;
                                margin-left: 2px;
                            }

                            input:checked:before {
                                content: "✔";
                                color: #4F23E2;
                                font-weight: 600;
                                font-size: 13px;
                                margin-left: 3px;
                            }

                            input:checked {
                                margin-left: 2px;
                            }
                        }
                    }
                }
            }
            .divider {
                display: flex;
                width: 97%;
                height: 4px;
                background-color: #EAEAEA;
                justify-self: center;
            }

        }

    }

    .submit {
        width: calc(100% - 20px);
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button {
            border-radius: 5px;
            width: 280px;
            height: 50px;
            background-color: #4F23E2;
            color: white;
            font-size: 18px;
            font-family: Inter;
            font-weight: 600;
            border: none;
            cursor: pointer;
        }
    }
}


/******************************* Teacher *****************************/

.teacher-content {
    margin-left: 29px;
    width: calc(100% - (29px * 2));

    border-radius: 26px;
    border: 1px solid lightgrey;
    box-shadow: 5px 5px 5px 0px lightgray;
    max-width: 1200px;
    overflow: auto;
    padding: 20px;

    .question {
        width: 100%;
        height: fit-content;

        .body {
            height: fit-content;
            width: calc(100% - (20px * 2));
            margin-left: 20px;

            .header {
                width: 100%;
                height: 83px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                .left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: start;

                    .nb-question {
                        font-size: 27px;
                        font-family: Inter;
                        font-weight: 600;
                    }

                    .title-question {
                        font-size: 27px;
                        font-family: Inter;
                        font-weight: 600;
                    }
                }

                .up-arrow {
                    rotate: 270deg;
                }

                .down-arrow {
                    rotate: 90deg;
                    margin-right: 4px;
                }
            }

            .details {
                width: 100%;
                height: fit-content;

                .text-list {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    font-family: 'Inter';
                    font-size: 20px;
                }

                .emoji-row {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    margin-bottom: 20px;
                    flex-wrap: wrap;

                    .emoji-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px;
                        flex-wrap: wrap;

                        img {
                            cursor: pointer;
                        }

                        .percentage-container {
                            width: 100px;
                            height: 50px;
                            border-radius: 26px;
                            border: 4px solid #EAEAEA;
                            background-color: white;
                            justify-content: center;
                            align-items: center;
                            display: flex;

                            .emoji-text {
                                font-family: 'Inter';
                                font-size: 20px;
                            }
                        }
                    }
                }

                .multiple {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    margin-bottom: 20px;
                    flex-wrap: wrap;

                    .answer {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px;
                        flex-wrap: wrap;
                        font-size: 20px;
                        font-family: Inter;
                        font-weight: 600;

                        .percentage-container {
                            width: 100px;
                            height: 50px;
                            border-radius: 26px;
                            border: 4px solid #EAEAEA;
                            background-color: white;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                        }
                    }
                }
            }
        }
        .divider {
            display: flex;
            width: 100%;
            height: 4px;
            background-color: #EAEAEA;
        }

    }
}