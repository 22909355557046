.alert-box {
    width: calc(100% - 68px);
    height: 100%;
    border-radius: 26px;
    box-shadow: 5px 5px 5px 0px lightgray;
    border: 1px solid lightgrey;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    .alert-header {
        width: calc(100% - 28px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 18px;
        margin-right: 18px;
        align-items: center;

        .title {
            color: #4F23E2;
            font-size: 30px;
            font-weight: 600;
            font-family: Inter;
        }

        @media (max-height: 600px) {
            .title {
              font-size: 25px;
            }
          }

        .see-more {
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            color: #4F23E2;
            font-size: 18px;
            font-weight: 600;
            font-family: Inter;

            .img {
                margin-left: 4px;
            }
        }
    }

    .alert-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 10px;
        width: calc(100% - 40px);
        min-height: 80px;
        max-height: 600px;
        overflow-y: auto;
        flex-wrap: wrap;

        .w-100 {
            width: 100%;
            gap: 10px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .alert-container {
                display: flex;
                flex-direction: column;
                box-shadow: 5px 5px 5px 0px lightgray;
                border: 1px solid lightgrey;
                border-radius: 26px;
                height: 112px;
                width: 100%;

                .content {
                    width: calc(100% - 20px);
                    height: 100%;
                    margin: 5px 10px 5px 10px;

                    .header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .user-profile {
                            flex: 0.7;
                        }

                        .see-more-inverted {
                            flex: 0.3;
                            display: flex;
                            flex-direction: row;
                            justify-content: end;
                            align-items: center;
                            color: #4F23E2;
                            font-size: 18px;
                            font-weight: 600;
                            font-family: Inter;

                            .img {
                                margin-left: 4px;
                            }
                        }
                    }

                    .body {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 600;
                        color: black;
                        margin-top: 3px;
                    }
                }

            }
        }

    }
}