.form-page {
    width: 100%;
    height: 100%;

    .content {
        margin-left: 29px;
        width: calc(100% - (29px * 2));
        height: calc(100% - 104px);
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 25px;

        .no-questionnaire {
            width: 100%;
            height: 100%;
            font-size: 24px;
            font-weight: 600;
            color: black;
        }

        .dateRange {
            width: 100%;
            height: fit-content;
            margin-bottom: 20px;
            border-radius: 26px;
            border: 1px solid lightgrey;
            box-shadow: 5px 5px 5px 0px lightgray;
            max-width: 1000px;
            min-width: 250px;
            padding: 20px;

            .header {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: fit-content;
                font-size: 27px;
                font-weight: 600;
                color: black;
                white-space: nowrap;
                align-items: center;
                justify-content: start;

                .divider {
                    margin-left: 26px;
                    display: flex;
                    width: 100%;
                    height: 4px;
                    background-color: #9699FF;
                }
            }

            .questionnaire {
                display: flex;
                flex-direction: row;
                width: calc(100% - 8px);
                height: 50px;
                align-items: center;
                justify-content: space-between;
                margin:10px;

                .content-q {
                    width: 100%;
                    margin-right: 20px;
                    margin-left: 20px;
                    font-size: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row;
                    font-weight: 400;
                    color: #292929;
                    white-space: nowrap;


                    Link:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}


/****************TEACHER**************************/
.form-container {
    margin-left: 30px;
    margin-right: 0px;
    margin-bottom: 20px;
    height: calc(100vh - 120px);
    overflow-y: auto;

    #popup-root {

        .popup-overlay {

            .popup-content {

                .popup-modal-container {
                    display: flex;
                    flex-direction: column;
                    gap: 25px;

                    .title-popup {
                        font-size: 36px;
                    }

                    .content-popup, .error-message {
                        font-size: 22px;
                    }

                    .btn-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;

                        .popup-btn {
                            border: 4px solid #4F23E2;
                            background-color: white;
                            border-radius: 15px;
                            height: 45px;
                            width: 362px;
                            font-size: 22px;
                            padding-left: 25px;
                        }

                        .save-btn-container {
                            display: flex;
                            flex-direction: row;
                            gap: 25px;

                            .popup-btn {
                                border: 4px solid #4F23E2;
                                background-color: white;
                                border-radius: 15px;
                                height: 45px;
                                width: 362px;
                                font-size: 22px;
                                padding-left: 25px;
                            }
                        }
                    }
                }
            }
        }

    }

    .form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 30px;
        flex-wrap: wrap;

        .error-message-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .error-message {
                color: red;
                font-size: 20px;
            }
        }

    }

    .form-content-container {
        width: calc(100% - (29px * 2));
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        border-radius: 26px;
        border: 1px solid lightgrey;
        box-shadow: 5px 5px 5px 0px lightgray;
        justify-content: space-between;
        padding: 20px;

        .head-form {
            display: flex;
            flex-direction: column;
            gap: 25px;
            flex-wrap: wrap;
            margin-bottom: 25px;

            .input-container {

                .label-content {
                    font-size: 22px;
                }

                .default-input {
                    border: none;
                    background-color: #EAEAEA;
                    border-radius: 15px;
                    height: 45px;
                    width: 700px;
                    font-size: 22px;
                    padding-left: 25px;
                }
            }

            .label-container {

                .input-label {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    font-size: 22px;
                    align-items: center;

                    .default-input {
                        border: 1px solid lightgrey;
                        box-shadow: 5px 5px 5px 0px lightgray;
                        border-radius: 15px;
                        height: 45px;
                        width: 200px;
                        font-size: 22px;
                        padding-left: 25px;
                        cursor: pointer;
                    }

                    .date-input {
                        width: 200px;
                    }
                }

            }
        }

        .question {

            .body {
                font-size: 22px;
                display: flex;
                flex-direction: column;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    align-items: center;

                    .input-label {
                        flex-direction: row;
                        gap: 10px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;

                        .default-input {
                            background-color: #EAEAEA;
                            border: none;
                            border-radius: 15px;
                            height: 45px;
                            width: 362px;
                            font-size: 22px;
                            padding-left: 25px;
                        }

                        .default-select {
                            background-color: white;
                            border: 1px solid lightgrey;
                            box-shadow: 5px 5px 5px 0px lightgray;
                            font-family: Inter;
                            border-radius: 15px;
                            height: 45px;
                            width: 362px;
                            font-size: 22px;
                            padding-left: 25px;
                        }

                    }
                }

                .body-content {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-left: 30px;
                    align-items: center;

                    .answers {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 20px;

                        .default-input {
                            border: none;
                            background-color: #EAEAEA;
                            font-family: Inter;
                            border-radius: 15px;
                            height: 45px;
                            min-width: 250px;
                            width: 29%;
                            font-size: 22px;
                            padding-left: 25px;
                        }
                    }

                    .btn-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 20px;

                        .form-btn {
                            border-radius: 26px;
                            border: none;
                            color: #4F23E2;
                            width: 350px;
                            height: 45px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                            font-family: Inter;
                            font-weight: 600;
                            cursor: pointer;
                        }
                    }
                }
            }

            .horizontal-line {
                width: 95%;
                border-bottom: 4px solid #EAEAEA;
                margin-bottom: 10px;
                padding-right: 40px;
            }
        }
    }
}

.confirmation-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-btn {
        border-radius: 26px;
        border: none;
        color: #4F23E2;
        width: 350px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: Inter;
        font-weight: 600;
        cursor: pointer;
    }
}

.popup-modal-container {
    font-family: Inter;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: start;

    .title-popup {
        font-size: 36px;
    }

    .content-popup, .error-message {
        font-size: 22px;
        color: #5E5E5E;
    }

    .btn-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .popup-btn {
            border-radius: 26px;
            border: none;
            background-color: #4F23E2;
            width: 120px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-family: Inter;
            font-weight: 600;
            color: white;
            cursor: pointer;
        }

        .save-btn-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 75px;

            .popup-text-btn {
                border: none;
                font-weight: 600;
                font-size: 22px;
            }

            .popup-btn {
                border-radius: 5px;
                border: none;
                background-color: #4F23E2;
                width: 350px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                font-family: Inter;
                font-weight: 600;
                color: white;
                cursor: pointer;
            }
        }
    }
}